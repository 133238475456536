export interface QuestionParams {
  number: number;
  answer: string;
  image: string;
}

export default class Question {
  public number: number
  public answer: string
  public image: string

  public constructor({
    number, answer, image
  }: QuestionParams) {
    this.number = number
    this.answer = answer
    this.image = image
  }
}