import React, { FunctionComponent } from 'react'
import Heading from '../styled/heading'
import { useQuizContext } from '../contexts/quiz-context'
import styled from 'styled-components'

type CounterProps = {}

export const Counter: FunctionComponent<CounterProps> = (props: CounterProps): JSX.Element => {
  const { currentQuestion, totalQuestions } = useQuizContext()

  return (
    <CounterCard>
      <Heading>{ `${currentQuestion}/${totalQuestions}`}</Heading>
    </CounterCard>
  )
}

export const CounterCard = styled.div`
  margin-bottom: 2rem;
  text-align: right;
`;

export default Counter