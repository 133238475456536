import styled from 'styled-components'

export const Form = styled.form`
  position: absolute;
  bottom: 0;
  padding: 0 1rem;
  max-width: 36rem;
  margin: 3rem auto 6rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
`;

export default Form;