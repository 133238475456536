import React, { useContext, useState } from 'react'

interface ContextProps {
  totalQuestions: number;
  currentQuestion: number;
  setCurrentQuestion: Function,
  results: boolean[],
  addResult: Function,
  result: Result,
  setResult: Function
}

export enum Result {
  Unknown = "not_answered",
  Correct = "correct",
  Wrong = "wrong"
}

const QuizContext = React.createContext<ContextProps>({
  totalQuestions: 14,
  currentQuestion: 1,
  setCurrentQuestion: (value: number) => {},
  results: [],
  addResult: (value: boolean) => {},
  result: Result.Unknown,
  setResult: (value: Result) => {}
})

export const useQuizContext = (): any => useContext(QuizContext)

export const QuizContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [totalQuestions] = useState<number>(14)
  const [currentQuestion, setCurrentQuestion] = useState<number>(1)
  const [results, setResults] = useState<boolean[]>([])
  const [result, setResult] = useState<Result>(Result.Unknown);

  const addResult = (value: boolean): void => {
    setResults(results.concat([value]));
  };

  return (
    <QuizContext.Provider value={{ currentQuestion, setCurrentQuestion, results, addResult, totalQuestions, result, setResult }}>
      {props.children}
    </QuizContext.Provider>
  )
}

export default QuizContext