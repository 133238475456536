import React, { FunctionComponent, Ref } from 'react'
import styled from 'styled-components'

type InputProps = {
  disabled: boolean,
  value: string,
  inputRef: Ref<HTMLInputElement>
}

export const Input: FunctionComponent<InputProps> = (props: InputProps): JSX.Element => {
  return (
    <>
      <StyledInput id="answer" name="answer" type="input" ref={props.inputRef} disabled={props.disabled} placeholder='Name this film...' defaultValue={props.value} required />
      <StyledSpan>Enter</StyledSpan>
    </>
  )
}

const StyledInput = styled.input`
  font-family: inherit;
  width: 20rem;
  border: 0;
  border-bottom: 2px solid #FFFFFF;
  outline: 0;
  font-size: 1.3rem;
  color: #FFFFFF;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  & + span {
    display: none;
  }

  &::placeholder {
    color: #FFFFFF;
  }

  &:focus {
    padding-bottom: 6px;  
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
  }

  &:valid {
    & + span {
      display: inline-block;
    }
  }
`

const StyledSpan = styled.span`
    color: #FFFFFF;
    right: 2rem;
    display: inline-block;
    position: absolute;
    border: 1px #FFF solid;
    border-radius: 6px;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
    top: 0.35rem;
`

export default Input;