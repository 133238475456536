import React from 'react';
import QuizForm from './components/quiz-form'
import Quiz from './styled/quiz';
import Byline from './styled/byline';
import { useQuizContext } from './contexts/quiz-context';
import QuestionService from './services/question-service';
import Complete from './components/complete';
import Wrapper from './styled/wrapper';

function App() {
  const { currentQuestion, result } = useQuizContext()
  const question = new QuestionService().nextQuestion(currentQuestion)
  
  return (
    <Wrapper style={{ backgroundImage: `url("./images/${question && question.image}")`, backgroundColor: `${!question && 'black'}` }} className={result}>
      <Quiz>
        { question && <QuizForm question={question}/> }
        { question === null && <Complete />}
      </Quiz>
      <Byline>Tommy loves a quiz</Byline>
    </Wrapper>
  );
}

export default App;
