import styled from 'styled-components'
import { FunctionComponent, Ref } from 'react'
import React from 'react'

type LinkProps = {
  onClick: (event) => void,
  linkRef?: Ref<HTMLAnchorElement>
}

export const Link: FunctionComponent<LinkProps> = (props: React.PropsWithChildren<LinkProps>): JSX.Element => {
  return (
    <StyledLink onClick={props.onClick} ref={props.linkRef}>{props.children}</StyledLink>
  )
}

export const StyledLink = styled.a`
  margin-top: 1rem;
  position: absolute;
  color: #FFFFFF;
  cursor: pointer;

  &:focus {
    border: 2px red solid;
  }
`;

export default Link