import styled from 'styled-components'

export const Byline = styled.h4`
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1.25rem;
  position: absolute;
  bottom: 0px;
  right: 1.5rem;
`;

export default Byline;