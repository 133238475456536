import React, { FunctionComponent } from 'react'
import Heading from '../styled/heading'
import { useQuizContext } from '../contexts/quiz-context'
import Form from '../styled/form'

type CompleteProps = {}

export const Complete: FunctionComponent<CompleteProps> = (props: CompleteProps): JSX.Element => {
  const { totalQuestions, results } = useQuizContext()

  return (
    <Form>
      <Heading>You scored { `${results.filter((result) => result === true).length}/${totalQuestions}`}</Heading>
    </Form>
  )
}

export default Complete