import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;

  &.wrong {
    background-color: red;
    input {
      animation: shake 0.2s ease-in-out 0s 2;
    }
  }

  &.correct {
    background-color: green;
  }

  @keyframes shake {
    0% { margin-left: 0rem; }
    25% { margin-left: 0.1rem; }
    75% { margin-left: -0.1rem; }
    100% { margin-left: 0rem; }
  }
`;

export default Wrapper;