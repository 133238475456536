import React, { FunctionComponent, useState, FormEvent, createRef, useEffect } from 'react'
import { Form } from '../styled/form'
import Question from '../models/question'
import { useQuizContext, Result } from '../contexts/quiz-context'
import Counter from './counter'
import Link from '../styled/link'
import Input from '../styled/input'

type QuizFormProps = {
  question: Question
}

type TextInputProps = {
  answered: boolean;
}

export const QuizForm: FunctionComponent<QuizFormProps> = (props: QuizFormProps): JSX.Element => {
  const { currentQuestion, setCurrentQuestion, addResult, result, setResult } = useQuizContext()
  const [answered, setAnswered] = useState<boolean>(false);

  const textInput = createRef<HTMLInputElement>();
  const nextLink = createRef<HTMLAnchorElement>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const answer = textInput.current?.value.toLowerCase()
    const result = answer === props.question.answer.toLowerCase()

    addResult(result)
    setResult(result ? Result.Correct : Result.Wrong)
    setAnswered(true)
    textInput.current?.setAttribute("value", props.question.answer)
  };

  const handleNext = (event) => {
    event.preventDefault()
    setAnswered(false)
    setResult(Result.Unknown)
    setCurrentQuestion(currentQuestion + 1)
  };

  useEffect(()=>{
    if(answered) {
      nextLink.current?.focus()
    }
    else {
      textInput.current?.focus()
    }
  })

  return (
    <>
      <Counter/>
      <Form onSubmit={handleSubmit}>
        <Input key={result} disabled={answered} inputRef={textInput} value={ answered ? props.question.answer : '' } />
        <br />
        { answered && <Link onClick={handleNext} linkRef={nextLink}>Next &gt;&gt;&gt;</Link>}
      </Form>
    </>
  )
}

export default QuizForm