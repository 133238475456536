import Question from "../models/question"

import data from '../data/questions.json'

class QuestionService {
  public nextQuestion(number: number): Question | null {
    if(data.length >= number) {
      const questionJson = data[number - 1]
      return new Question({...questionJson, number: number })
    }
    return null;
  }
}

export default QuestionService